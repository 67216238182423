import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: "/",
        redirect: "/layout"
    },
    {
        path: '/layout',
        name: 'layout',
        component: () => import('../views/layout.vue'),
        redirect: "/home",
        meta: {
            title: "首页"
        },
        children: [{
            path: '/home',
            name: 'home',
            component: () => import('../views/home.vue'),
            meta: {
                title: "首页",
            }
        }]
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router