<template>
    <div id="app">
        <keep-alive>
            <router-view v-if="$route.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.keepAlive" />
    </div>
</template>

<script>
    export default {
        name: 'App',
    }
</script>

<style>
    body {
        width: 100%;
        height: 100vh;
        margin: 0;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #000000;
    }
</style>